import React, { useState, useEffect } from 'react';
import reportsService from '../../../services/reports';
import loaderIcon from '../../../assets/loader.svg';

import Utils from '../../../components/Utils/Utils';
import ScrollableTable from '../../../components/DataGrid/DataGrid';

function TotalCompanyView({selectedDate}) {
  const [monthlyReport, setMonthlyReport] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchMonthlyTotalByCompanyReport = async () => {
      try {
        if(typeof selectedDate !== 'object' && selectedDate !== null) {
          setMonthlyReport([]);
          const result = await reportsService.getMonthlyTotalByCompany(selectedDate);
        
          if (Array.isArray(result.data)) {
            setMonthlyReport(result.data);
          } else {
            console.error('Invalid data structure for MonthlyReport:', result);
          }
          setLoader(false);
        }
      } catch (error) {
        console.error('Error fetching expenses:', error);
      }
    };

    fetchMonthlyTotalByCompanyReport();
  }, [selectedDate]);
  
  const dynamicColumns = [
    {
      Header: 'Pagamentos Banco - Por Empresa',
      accessor: 'Company',
      width: 200,
      textAlign: 'left',
    },
    {
      Header: 'R$ Acumulado',
      accessor: 'TotalAmount',
      width: 140,
      textAlign: 'left',
    },
  ];

  const rows = monthlyReport && monthlyReport.map((item, index) => {
    return {
      Company: item.Company,
      TotalAmount: item.TotalAmount ? Utils.currencyFormat(item.TotalAmount) : '-'
    }
  });

  const TotalAmount = monthlyReport ? monthlyReport.reduce((sum, item) => sum + item.TotalAmount, 0) : 0;

  const tableFooter = (
    <>
      <tr className='tfoot'>
        <td><strong>Total:</strong></td>
        <td><strong>{ TotalAmount ? Utils.currencyFormat(TotalAmount) : '-' }</strong></td>
      </tr>
    </>
  );
  
  return (
    <>
        <div className='wrapper-report' style={{ marginTop: '20px' }}>
            {
                loader && (monthlyReport === null) ? (
                    <img src={loaderIcon} alt="Carregando..." width={100} height={100} />
                ) : (
                  monthlyReport && 
                    <ScrollableTable headers={dynamicColumns} height={'100%'} footer={tableFooter} dataRows={rows}  />
                    
                )
            }
        </div>
    </>
  );
}

export default TotalCompanyView;
